<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="568px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="aggree">
		 <!-- <div class="agree-title">{{type == 1?'隐私政策':'用户协议'}}</div> -->
		 <div class="agree-content" v-html="content"></div>
	 </div>
	  <span slot="footer" class="dialog-footer">
		  <div class="dialog-footer-button" @click="close()">阅读并同意</div>
	  </span>
	</el-dialog>
</template>

<script>
	import {protocol} from "@/api/index"
	export default {
		data(){
			return {
				dialogVisible:false,
				type:"",
				content:""
			}
		},
		methods:{
			init(type){
				//获取协议内容
				this.type = type;
				protocol({type:type+1}).then(res=>{
					if(res.code == 0){
						this.content = res.data.content;
						this.dialogVisible = true;
					}else{
						this.$message.error(res.msg);
					}
					
				})
			},
			close(){
				this.$emit("close");
				this.dialogVisible = false;
			}
		}
	}
</script>


<style scoped lang="scss">
	.aggree{
		.agree-title{
			font-weight: 500;
			font-size: 16px;
			color: #3B3B3B;
			line-height: 22px;
			text-align: center;
		}
		.agree-content{
			font-weight: 400;
			font-size: 14px;
			color: #3B3B3B;
			line-height: 20px;
			padding: 20px 24px;
			box-sizing: border-box;
			height: 40vh;
			overflow: auto;
		}
	}
	.dialog-footer-button{
		width: 166px;
		height: 50px;
		background: #FF8D1F;
		border-radius: 6px;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	::v-deep .el-button{
		width: 80px;
		height: 30px;
		border-radius: 6px;
		border: 1px solid #3F81FF;
		line-height: 30px;
		padding: 0px;
		font-weight: 400;
		font-size: 12px;
		color: #3F81FF;
		text-align: center;
	}
	::v-deep .el-button--primary{
		color: #fff;
	}
	::v-deep .el-input__inner{
		width: 308px;
		height: 44px;
		background: #F9F9F9;
		border-radius: 6px;
		border: 0px;
	}
</style>