<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="590px" custom-class="addModel" :center="true"
		:append-to-body="true" :show-close="false" :close-on-click-modal="false">
		<template v-if="step == 1">
			<div class="question">
				<div class="question-title">拖动蓝框进行重新切题</div>
				<div class="cropper">
					<vue-cropper ref="cropper" v-if="dialogVisible" :img="info.imageUrl" :info="true"
						:autoCrop="options.autoCrop" :autoCropWidth="options.autoCropWidth"
						:autoCropHeight="options.autoCropHeight" :fixedBox="options.fixedBox" :full="true"
						outputType="png" :outputSize="1"></vue-cropper>
				</div>

			</div>
			<span slot="footer" class="dialog-footer flex-midden flex-center">
				<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">取消</el-button>
				<el-button type="primary" class="dialog-footer-two" :loading="loading" @click="submit">确定</el-button>
				<el-button type="primary" class="dialog-footer-two" @click="step=2">重新上传题目</el-button>
			</span>
		</template>
		<template v-if="step == 2">
			<div class="question">
				<div class="question-title">请您上传题目</div>
				<div class="question-tip">拖动蓝框进行切图</div>
				<el-upload v-if="!image" :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess" style="text-align: center;">
					<div class="upload-image">
						<img src="@/assets/images/create/photo.png"/>
						<div>点击上传图片</div>
					</div>
				</el-upload>
				<div class="cropper" v-else>
					<vue-cropper ref="cropper" :img="image" :info="true"
						:autoCrop="options.autoCrop" :autoCropWidth="options.autoCropWidth"
						:autoCropHeight="options.autoCropHeight" :fixedBox="options.fixedBox" :full="true"
						outputType="png" :outputSize="1"></vue-cropper>
				</div>
			</div>
			<span slot="footer" class="dialog-footer flex-midden flex-center">
				<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">取消</el-button>
				<el-button type="primary" class="dialog-footer-two" :loading="loading" @click="submit">确定</el-button>
				<el-button type="primary" class="dialog-footer-two" v-if="image" @click="image=''">重新选择图片</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		uploadimage,
		updatePaperQuestion
	} from "@/api/index"
	import {uploadUrl} from "@/api/config"
	import {
		VueCropper
	} from 'vue-cropper'
	export default {
		components: {
			VueCropper
		},
		data() {
			return {
				dialogVisible: false,
				info: {},
				options: {
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 492, // 默认生成截图框宽度
					autoCropHeight: 207, // 默认生成截图框高度
					fixedBox: false, // 固定截图框大小 不允许改变
				},
				image: "",
				loading: false,
				step: 1,
				upload:""
			}
		},
		methods: {
			init(info) {
				this.upload = uploadUrl();
				this.loading = false;
				this.info = info;
				this.step = 1;
				this.image = "";
				this.dialogVisible = true;
			},
			submit() {
				this.loading = true;
				try {
					this.$refs.cropper.getCropData(data => {
						uploadimage(data).then(res => {
							if (res.code == 0) {
								this.submitData(res.fileName);
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						})
					})
				} catch (e) {
					this.loading = false;
					this.$message.error("切图失败");
				}

				// this.dialogVisible = false;
			},
			//提交图片修改
			submitData(file) {
				updatePaperQuestion({
					id: this.info.id,
					newPaperId: this.info.paper_id,
					questionId: this.info.questionId,
					imageUrl: file,
					answerUrl: ""
				}).then(res => {
					if (res.code == 0) {
						this.dialogVisible = false;
						this.$emit("update");
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				})
			},
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.image = e.response.filePath;
				}else{
					this.$message.error("图片上传失败");
				}
			},
		}
	}
</script>


<style scoped lang="scss">
	.question {
		position: relative;
		.question-title {
			font-weight: 500;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			padding: 0px 0px 20px;
			text-align: center;
		}
		.question-tip{
			width: 119px;
			height: 27px;
			background: #FFF6F6;
			border-radius: 10px 100px 100px 0px;
			font-weight: 400;
			font-size: 12px;
			color: #FF5858;
			line-height: 27px;
			text-align: center;
			position: absolute;
			right: 60px;
			top: 10px;
		}

		.cropper {
			width: 492px;
			height: 407px;
			margin: 0 auto;
		}
		
		.upload-image{
			width: 492px;
			border-radius: 8px;
			border: 2px solid #FF3838;
			margin: 0 auto;
			padding: 68px 0px;
			img{
				width: 47px;
				height: 45px;
			}
			div{
				font-weight: 400;
				font-size: 14px;
				color: #CFCFD4;
				line-height: 20px;
			}
		}
	}

	.dialog-footer {
		margin-top: 30px;
		.dialog-footer-two {
			width: 122px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}

		.dialog-footer-one {
			width: 122px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #fff;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	}

	::v-deep .addModel {
		border-radius: 10px !important;
	}

	::v-deep .el-dialog__title {
		height: 0px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		padding: 0px;
	}
</style>