<template>
	<div class="view">
		<header class="header">
			<div class="header-content">
				<div class="header-content-title flex-start flex-center">
					<img src="@/assets/images/logo2.png"/>
					International test paper
				</div>
				
				<div class="header-tab flex-start flex-center">
					<div @click="toindex" class="header-tab-item  flex-center flex-midden" :class="nav==1?'active':''">
						<img v-if="nav == 1" src="@/assets/images/icon11.png"/>
						<img v-else src="@/assets/images/icon22.png"/>
						<div>自组试卷管理</div>
					</div>
					<div @click="tocenter" class="header-tab-item flex-center flex-midden" :class="nav==2?'active':''">
						<img v-if="nav == 2" src="@/assets/images/icon1.png"/>
						<img v-else src="@/assets/images/icon2.png"/>
						<div>个人中心</div>
					</div>
				</div>
			</div>
		</header>
		<div class="view-content">
			<router-view/>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				nav:1
			}
		},
		
		watch: {
		    $route(to) {
		      this.nav = to.meta.nav;
		    }
		},
		mounted(){
			this.nav = this.$route.meta.nav;
		},
		methods:{
			exit(){
				this.$refs.exitMsg.init("是否确定退出登录");
			},
			toindex(){
				this.$router.replace({name:"index"});
			},
			tocenter(){
				this.$router.replace({name:"centerindex"});
			}
		}
	}
</script>

<style lang="scss">
	.view-content{
		min-width: 1280px;
		margin: 0 auto;
	}
	.view{
		min-width: 1280px;
		.header{
			width: 100%;
			height: 94px;
			background: #FF8D1F;
			position: fixed;
			top: 0px;
			left: 0px;
			right: 0px;
			z-index: 10;
		}
		.header-content{
			height: 94px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin: 0px 20px;
			.header-content-title{
				img{
					width: 55px;
					height: 55px;
					margin-right: 7px;
				}
				font-weight: 900;
				font-size: 29px;
				color: #FFFFFF;
			}
			
			.header-tab{
				.header-tab-item{
					margin-left: 50px;
					width: 340px;
					height: 60px;
					background: rgba(244,247,248,0.25);
					border-radius: 8px;
					cursor: pointer;
					img{
						width: 22px;
						height: 22px;
					}
					div{
						font-weight: 600;
						font-size: 15px;
						color: #FFFFFF;
						margin-left: 7px;
					}
				}
				.active{
					width: 340px;
					height: 60px;
					background: #F4F7F8;
					border-radius: 8px;
					div{
						color: #000;
					}
				}
			}
		}
	}
</style>