<template>
	<div class="content">
		<div class="content-table">
			<div class="content-table-nav flex-center flex-midden">
				<div @click="type = 1" :class="type==1?'active':''">考卷列表</div>
				<div @click="type = 2" :class="type==2?'active':''">我的考卷</div>
			</div>
			<template v-if="type == 1">
				<div class="content-table-search">
					<div class="content-table-search-value flex-start flex-center flex-wrap">
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">试卷名称</div>
							<el-input type="text" placeholder="请输入"></el-input>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">科目</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">考试局</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">考试类型</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">题目类型</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">积分</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">密码</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">试卷状态</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">指定作答</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-button flex-start flex-center">
							<div>重置</div>
							<div>搜索</div>
						</div>
					</div>
				</div>
				
				 <el-table :data="tableData" style="width: 100%">
					<el-table-column prop="date" label="真题试卷名称"></el-table-column>
					<el-table-column prop="date" label="考试局"></el-table-column>
					<el-table-column prop="date" label="科目"></el-table-column>
					<el-table-column prop="date" label="年份"></el-table-column>
					<el-table-column prop="date" label="考试季"></el-table-column>
					<el-table-column prop="date" label="试卷类型"></el-table-column>
					<el-table-column prop="date" label="操作" width="200">
						<template slot-scope="scope">
							<div @click="toinfo" class="see-button flex-center flex-midden">
								<img src="@/assets/images/see.png"/>
								<div>查看</div>
							</div>
						</template>
					</el-table-column>
				  </el-table>
				  <div class="table-bottom flex-between flex-center">
						  <div class="table-bottom-left flex-start flex-center">
						  </div>
					  
					  
						<div class="table-bottom-right">
							<el-pagination :current-page="page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
				  </div>
			</template>
			
			<template v-if="type == 2">
				<div class="content-table-search">
					<div class="content-table-search-value flex-start flex-center flex-wrap">
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">试卷名称</div>
							<el-input type="text" placeholder="请输入"></el-input>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">科目</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">考试局</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">考试类型</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">题目类型</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">积分</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">密码</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">试卷状态</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-item flex-start flex-center">
							<div class="content-table-search-value-title">创建时间</div>
							<el-select placeholder="请选择课程体系">
								<el-option label="课程体系" :value="1"></el-option>
							</el-select>
						</div>
						<div class="content-table-search-value-button flex-start flex-center">
							<div>重置</div>
							<div>搜索</div>
						</div>
					</div>
				</div>
				
				 <el-table :data="tableData" style="width: 100%">
					<el-table-column prop="date" label="真题试卷名称"></el-table-column>
					<el-table-column prop="date" label="考试局"></el-table-column>
					<el-table-column prop="date" label="科目"></el-table-column>
					<el-table-column prop="date" label="年份"></el-table-column>
					<el-table-column prop="date" label="考试季"></el-table-column>
					<el-table-column prop="date" label="试卷类型"></el-table-column>
					<el-table-column prop="date" label="操作" width="200">
						<template slot-scope="scope">
							<div @click="toinfo" class="see-button flex-center flex-midden">
								<img src="@/assets/images/see.png"/>
								<div>查看</div>
							</div>
						</template>
					</el-table-column>
				  </el-table>
				  <div class="table-bottom flex-between flex-center">
						  <div class="table-bottom-left flex-start flex-center">
						  </div>
					  
					  
						<div class="table-bottom-right">
							<el-pagination :current-page="page" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>
				  </div>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				tableData:[
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"},
					{date:"123"}
				],
				page:1,
				total:100,
				type:1
			}
		},
		methods:{
			toinfo(){
				this.$router.push("/examinfo");
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>