import request from '@/utils/request'

import VueCookies from 'vue-cookies'
// 登录接口
export function login(data) {
  return request({
    url: 'backend/teacher/login',
    method: 'post',
	data:data
  })
}

// 获取协议的相关接口
export function protocol(data) {
  return request({
    url: 'sys/agreement/type',
    method: 'get',
	params:data
  })
}


// 忘记密码
export function updatePassword(data) {
  return request({
    url: 'backend/teacher/updatePassword',
    method: 'post',
	data:data
  })
}




// 获取验证码接口
export function sendCode(data) {
  return request({
    url: 'backend/teacher/code',
    method: 'post',
	data:data
  })
}


//获取试卷列表
export function newPaperList(data) {
  return request({
    url: 'backend/teacher/newPaperList',
    method: 'post',
	data:data
  })
}


//获取试卷列表
export function createpaper(data) {
  return request({
    url: 'backend/teacher/createpaper',
    method: 'post',
	data:data
  })
}

//检索满足条件的题目个数
export function queryPaperNum(data) {
  return request({
    url: 'backend/teacher/queryPaperNum',
    method: 'post',
	data:data
  })
}

//二次检索试卷详情
export function queryPaperQuestionList2(data) {
  return request({
    url: 'backend/teacher/queryPaperQuestionList2',
    method: 'post',
	data:data
  })
}



//获取试卷的详情
export function queryPaperQuestionList(data) {
  return request({
    url: 'backend/teacher/queryPaperQuestionList',
    method: 'post',
	data:data
  })
}

//删除试卷题目
export function deletePaperQuestion(data) {
  return request({
    url: 'backend/teacher/deleteQuestion',
    method: 'post',
	params:data
  })
}


//下载试卷
export function paperDownload(data) {
  return request({
    url: 'backend/teacher/newPaperAnswerDownload',
    method: 'post',
	data:data,
	responseType: 'blob'
  })
}


//获取用户基础数据
export function getUserByToken() {
  return request({
    url: 'paper/user/getUserByToken',
    method: 'post'
  })
}



//保存用户信息
export function dataFilling(data) {
  return request({
    url: 'backend/teacher/updateUserInfo',
    method: 'post',
	params:data,
	data:data
  })
}



//获取题目详情
export function getQuestionInfo(data) {
  return request({
    url: 'backend/teacher/getQuestion',
    method: 'get',
	params:data
  })
}


//上传图片功能
export function uploadimage(base64Data) {
	const arr = base64Data.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
  
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
  
	let blob = new Blob([u8arr], {type: mime});
	const formData = new FormData(); // 创建FormData对象
	formData.append('file', blob, 'image.png'); // 将文件添加到FormData对象中
	 
  return request({
    url: 'common/sysFile/upload2',
    method: 'post',
    headers: {
	  'Content-Type': 'multipart/form-data'
	},
	data:formData
  })
}



//修改题目内容
export function updatePaperQuestion(data) {
  return request({
    url: 'backend/teacher/updatePaperQuestion',
    method: 'post',
	data:data
  })
}

//保存试卷
export function saveNewPaper(data) {
  return request({
    url: 'backend/teacher/saveNewPaper',
    method: 'post',
	data:data
  })
}

//增加题目的
export function addPaperQuestion(data) {
  return request({
    url: 'backend/teacher/addPaperQuestion',
    method: 'post',
	data:data
  })
}

//获取题目类型
export function questionErrorTypes() {
  return request({
    url: 'backend/teacher/questionErrorTypes',
    method: 'post'
  })
}


//提交错误反馈
export function submitQuestionError(data) {
  return request({
    url: 'backend/teacher/submitQuestionError',
    method: 'post',
	data:data
  })
}


//预览考卷的接口
export function paperPreview(data) {
  return request({
    url: 'backend/teacher/queryPaperById',
    method: 'get',
	params:data
  })
}


//批量增加试题
export function addPaperQuestionList(data) {
  return request({
    url: 'backend/teacher/addPaperQuestionList',
    method: 'post',
	data:data
  })
}


//获取相关知识点
export function getKnowledgeList(data) {
  return request({
    url: 'backend/teacher/getKnowledgeList',
    method: 'get',
	params:data
  })
}

//获取试卷题目的详情
export function getQuestionInfoByPaper(data) {
  return request({
    url: 'backend/teacher/getByPaper',
    method: 'get',
	params:data
  })
}


//修改试卷的状态
export function updatePaperStatus(data) {
  return request({
    url: 'backend/teacher/updatePaperStatus',
    method: 'post',
	data:data
  })
}


//设置答题人
export function setUpAnswerUser(data) {
  return request({
    url: 'backend/teacher/setUpAnswerUser',
    method: 'post',
	data:data
  })
}




//查看考卷详情
export function getPaperById(data) {
  return request({
    url: 'backend/teacher/getPaperById',
    method: 'get',
	params:data
  })
}


//判卷列表
export function paperAnswerList(data) {
  return request({
    url: 'backend/teacher/paperAnswerList',
    method: 'post',
	data:data
  })
}




//获取个人考卷的情况
export function getNewPaperAnswers(data) {
	
	return request({
		url: 'paper/answers/getNewPaperAnswers',
		method: 'get',
		params:data
	})
}

//获取试卷详情
export function getQuestionListByPaper(data) {
	
	return request({
		url: 'paper/newPaper/getQuestionListByPaper',
		method: 'get',
		params:data
	})
}

//查看考卷报告
export function getPaperReport(data) {
  return request({
    url: 'paper/answers/getPaperReport',
    method: 'get',
	params:data
  })
}

//提交报告打分
export function paperAnswerMarkNotes(data) {
  return request({
    url: 'backend/teacher/paperAnswerMarkNotes',
    method: 'post',
	data:data
  })
}