<template>
	<div class="content">
		<div class="content-table-nav flex-start flex-center">
			<div class="active">自主试卷列表</div>
			<div @click="topager">自组判卷列表</div>
			<div @click="tocreate">新建自组试卷</div>
		</div>
		<div class="flex-between search-div">
			<el-form label-position="right" class="content-search" label-width="80px" :model="search">
				<el-row>
					  <el-col :span="6">
						  <el-form-item label="试卷名称">
						    <el-input v-model="search.name" size="small"></el-input>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="科目">
						    <el-select v-model="search.subject_id" size="small" placeholder="请选择" clear filterable style="width: 100%;">
						        <el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="考试局">
						    <el-select v-model="search.exam_office_id" size="small" collapse-tags multiple clear filterable placeholder="请选择" style="width: 100%;">
						        <el-option v-for="item in examname" :key="item.id" :label="item.name" :value="item.id"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="试卷类型">
						    <el-select v-model="search.paper_type"  size="small" collapse-tags clear filterable placeholder="请选择" style="width: 100%;">
						        <el-option v-for="item in paperTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="4">
						  <el-form-item label="题目类型">
						    <el-select v-model="search.question_type" size="small" collapse-tags clear filterable placeholder="请选择" style="width: 100%;">
						        <!-- <el-option v-for="item in questionTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option> -->
								<el-option label="选择题" :value="1"></el-option>
								<el-option label="综合题" :value="2"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="4">
						  <el-form-item label="积分">
						    <el-select v-model="search.integral" size="small" collapse-tags clear filterable placeholder="请选择" style="width: 100%;">
						        <el-option label="付费" :value="1"></el-option>
						        <el-option label="免费" :value="2"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="4">
						  <el-form-item label="密码">
						    <el-input v-model="search.password" size="small"></el-input>
						  </el-form-item>
					  </el-col>
					  <el-col :span="4">
						  <el-form-item label="试卷状态">
						    <el-select v-model="search.status" size="small" collapse-tags clear filterable placeholder="请选择" style="width: 100%;">
						        <el-option label="未上架" :value="1"></el-option>
						        <el-option label="已上架" :value="2"></el-option>
						        <el-option label="审核驳回" :value="3"></el-option>
						        <el-option label="已下架" :value="4"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="8">
						  <el-form-item label="创建时间">
						    <el-date-picker v-model="search.date" size="small" style="width: 100%;" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						    </el-date-picker>
						  </el-form-item>
					  </el-col>
					  
				</el-row>
			</el-form>
			<div class="search-button flex-start flex-center">
				<div @click="reset">重置</div>
				<div @click="getTopList">搜索</div>
			</div>
		</div>
		<div class="content-table">
			 <el-table :data="tableData" style="width: 100%">
				<el-table-column type="selection" label="全选"></el-table-column>
				<el-table-column prop="name" label="自组试卷名称" width="300"></el-table-column>
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.status == 0">未上架</div>
						<div v-if="scope.row.status == 1">上架中</div>
						<div v-if="scope.row.status == 2">已上架</div>
						<div v-if="scope.row.status == 3" @click="showReason(scope.row)" style="cursor: pointer;">上架被驳回</div>
						<div v-if="scope.row.status == 4">已下架</div>
						<!-- <div v-if="scope.row.status == 2">已上架</div> -->
					</template>
				</el-table-column>
				<el-table-column prop="question_type" label="题目类型"></el-table-column>
				<el-table-column prop="offices" label="考试局"></el-table-column>
				<el-table-column prop="subjects" label="科目"></el-table-column>
				<el-table-column prop="paper_type" label="试卷类型"></el-table-column>
				<el-table-column prop="password" label="密码"></el-table-column>
				<el-table-column prop="integral" label="积分">
					<template slot-scope="scope">
						<div v-if="scope.row.integral > 0">{{scope.row.integral}}积分</div>
						<div v-else>免费</div>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" width="160"></el-table-column>
				<el-table-column prop="date" label="操作" width="500">
					<template slot-scope="scope">
						<div class="flex-midden flex-center">
							<div class="see-button flex-center flex-midden" @click="tosee(scope.row)">
								<img src="@/assets/images/index/see.png"/>
								<div>查看</div>
							</div>
							<div v-if="scope.row.status == 2" class="see-button flex-center flex-midden" @click="changeStatus(scope.row.id, 4,'确认要下架吗？')">
								<img src="@/assets/images/index/down.png"/>
								<div>下架</div>
							</div>
							<div v-if="scope.row.status == 4 || scope.row.status == 0 || scope.row.status == 3" class="see-button flex-center flex-midden" @click="changeStatus(scope.row.id, 1,'确认要提交上架吗？')">
								<img src="@/assets/images/index/up.png"/>
								<div>提交上架</div>
							</div>
							<div v-if="scope.row.status == 4 || scope.row.status == 3" class="see-button flex-center flex-midden" @click="changeStatus(scope.row.id, 5,'确认要删除吗？')">
								<img src="@/assets/images/index/delete.png"/>
								<div>删除</div>
							</div>
							<div v-if="scope.row.status == 4 || scope.row.status == 3" class="see-button flex-center flex-midden" @click="edit(scope.row.id)">
								<img src="@/assets/images/index/edit.png"/>
								<div>编辑</div>
							</div>
							<div v-if="scope.row.participant == 1 && scope.row.status != 3" @click="updatePhone(scope.row)" class="see-button flex-center flex-midden">
								<img src="@/assets/images/index/student.png"/>
								<div>答题学生</div>
							</div>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  <div class="table-bottom flex-between flex-center">
					<div class="table-bottom-left flex-start flex-center">
						<div>已选择 <span>0</span> 条</div>
						<div class="flex-midden flex-center">
							<img src="@/assets/images/down.png"/>
							<span>下载自组试卷</span>
						</div>
					</div>
					<div class="table-bottom-right">
						<el-pagination :current-page="page" @size-change="changeSize" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="total" @current-change="getNextPage">
						</el-pagination>
					</div>
			  </div>
		</div>
		<notice ref="noticeRef" @ok="noticeSubmit"></notice>
		<reason ref="reasonRef"></reason>
		<phone ref="phoneRef" @update="getList"></phone>
		
	</div>
</template>

<script>
	import {subjectsList,examoffices,paperTypes,questionType} from "@/api/config"
	import {newPaperList,updatePaperStatus} from "@/api/index"
	import notice from "@/components/notice"
	import reason from "@/components/reason"
	import phone from "@/components/phone"
	export default {
		components:{
			notice,
			reason,
			phone
		},
		data(){
			return {
				tableData:[],
				page:1,
				total:0,
				pageSize:10,
				search:{
					name:"",
					subject_id:"",
					exam_office_id:[],
					paper_type:"",
					question_type:"",
					integral:"",
					password:"",
					status:"",
					date:[]
				},
				subject:[],
				examname:[],
				paperTypeList:[],
				questionTypeList:[]
			}
		},
		created(){
			this.getConfig();
			this.getList();
		},
		methods:{
			getConfig(){
				subjectsList().then(res=>{
					this.subject = res.data;
				})
				examoffices().then(res=>{
					this.examname = res.data;
				})
				//获取试卷类型
				paperTypes().then(res=>{this.paperTypeList = res.data;})
				//获取题目类别
				questionType().then(res=>{this.questionTypeList = res.data;})
			},
			tocreate(){
				this.$router.push("/create");
			},
			topager(){
				this.$router.push("/pager");
			},
			getList(){
				newPaperList({
					name:this.search.name,
					subject_id:Number(this.search.subject_id),
					exam_office_id:this.search.exam_office_id.length>0?(this.search.exam_office_id):null,
					paper_type:Number(this.search.paper_type),
					question_type:Number(this.search.question_type),
					integral:Number(this.search.integral),
					password:this.search.password,
					status:Number(this.search.status),
					start_time:this.search.date.length == 2?this.search.date[0]:'',
					end_time:this.search.date.length == 2?this.search.date[1]:'',
					user_id:Number(this.$cookies.get('teacheruserId')),
					limit:this.pageSize,
					offset:this.page,
					source:"client",
				}).then(res=>{
					if(res.code == 0){
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			getNextPage(e){
				this.page = e;
				this.getList();
			},
			reset(){
				this.search = {
					name:"",
					subject_id:"",
					exam_office_id:[],
					paper_type:"",
					question_type:"",
					integral:"",
					password:"",
					status:"",
					date:[]
				}
				this.getTopList();
			},
			tosee(info){
				window.open("/pagerinfo?id="+info.id);
			},
			//是否调整状态
			changeStatus(id, status,msg){
				this.$refs.noticeRef.init("温馨提示",msg,"确认",{id:id, status:status});
			},
			//改变条数
			changeSize(e){
				this.pageSize = e;
				this.getTopList();
			},
			//弹框确认的结果
			noticeSubmit(data){
				updatePaperStatus({
					status:data.status,
					id:data.id
				}).then(res=>{
					if(res.code == 0){
						this.getList();
						this.$message.success(res.msg);
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			edit(id){
				this.$router.push("/create?id="+id);
			},
			//展示驳回原因
			showReason(info){
				this.$refs.reasonRef.init(info.back_reason);
			},
			//答题学生
			updatePhone(info){
				this.$refs.phoneRef.init(info);
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>