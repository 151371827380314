<template>
	<div class="usercneter">
		<div class="center-content flex-start">
			<centerLeft :menu="2"></centerLeft>
			<div class="score-info">
				<!-- <div class="score-title">用户协议</div> -->
				<div class="score-content">
					<div v-html="content"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import centerLeft from "@/components/center-left.vue"
	import {protocol} from "@/api/index"
	export default {
		components:{
			centerLeft
		},
		data(){
			return {
				content:""
			}
		},
		mounted() {
			protocol({type:2}).then(res=>{
				if(res.code == 0){
					this.content = res.data.content;
				}else{
					this.$message.error(res.msg);
				}
			})
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
	.score-info{
		width: 100%;
	}
	.score-content{
		font-weight: 400;
		font-size: 14px;
		color: #777C85;
		line-height: 20px;
		padding: 0px 0px 27px;
	}
</style>