<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="840px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="question flex-between">
		  <i class="el-icon-arrow-left" @click="leftNum"></i>
		 <el-image class="question-left" :src="info.imageUrl" fit="contain"></el-image>
		 <div class="question-right">
			 <div class="question-right-top">
				 <div class="question-right-top-title">题目类型</div>
				 <div class="question-right-top-list flex-start flex-center flex-wrap">
					 <div class="question-right-top-list-item">考试局：{{info.examOfficeName}}</div>
					 <div class="question-right-top-list-item">类型：{{info.questionTypeName}}</div>
					 <div class="question-right-top-list-item">科目：{{info.subjectName}}</div>
					 <div class="question-right-top-list-item">考年：{{info.yearName}}年</div>
					 <div class="question-right-top-list-item">考季：{{info.examSeasonName}}</div>
					 <div class="question-right-top-list-item">难度：{{info.difficultyName}}</div>
					 <div class="question-right-top-list-item" style="width: 100%;">知识点：{{info.knowledgeName1}}-{{info.knowledgeName2}}-{{info.knowledgeName3}}</div>
				 </div>
			 </div>
			 <div class="question-right-bottom">
				 <div class="question-right-bottom-title">题目答案</div>
				<el-image class="question-right-bottom-image" :src="info.answerUrl" fit="contain"></el-image>
			 </div>
		 </div>
		  <i class="el-icon-arrow-right" @click="rightNum"></i>
	 </div>
		<span slot="footer" class="dialog-footer flex-midden flex-center">
			<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">返回</el-button>
			<el-button type="primary" class="dialog-footer-two" :loading="loading" @click="submit">题目错误提交</el-button>
			<el-button type="primary" class="dialog-footer-two" v-if="info.isAdd" style="background-color: #646464;border: 1px solid #646464;">加入试卷</el-button>
			<el-button type="primary" class="dialog-footer-two" v-else :loading="loading" @click="addPager">加入试卷</el-button>
		</span>
	</el-dialog>
</template>

<script>
	
	import {getQuestionInfo,addPaperQuestion} from "@/api/index"
	export default {
		data(){
			return {
				dialogVisible:false,
				info:{},
				index:-1,
				list:[],
				paper_id:0,
				loading:false,
				sort:0
			}
		},
		methods:{
			init(index, list,paper_id,sort){
				this.index = index;
				this.list = list;
				this.paper_id = paper_id;
				this.sort = sort;
				this.loading =false;
				this.getInfo();
			},
			getInfo(){
				getQuestionInfo({questionId:this.list[this.index].id,paperId:this.paper_id}).then(res=>{
					if(res.code == 0){
						this.info = res.data;
						if(!this.dialogVisible){
							this.dialogVisible = true;
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//左侧切图
			leftNum(){
				if(this.index <= 0){
					return this.$message.error("已经是第一道题了");
				}
				this.index--;
				this.getInfo();
				
			},
			//右侧切图
			rightNum(){
				if(this.index+1 >= this.list.length){
					return this.$message.error("已经是最后一道题了");
				}
				this.index++;
				this.getInfo();
				
			},
			submit(){
				this.dialogVisible = false;
				this.$emit("ok", this.list[this.index].id);
			},
			//加入试卷
			addPager(){
				this.loading = true;
				addPaperQuestion({
					newPaperId: this.paper_id, 
					questionId: this.list[this.index].id, 
					questionTitle: this.list[this.index].title, 
					imageUrl: this.info.imageUrl, 
					answerUrl: this.info.answerUrl, 
					sort: Number(this.sort)+1, 
					score: this.info.score, 
					questionType: this.info.questionType
				}).then(res=>{
					if(res.code == 0){
						this.info.isAdd = 1;
						this.loading = false;
						this.$emit("update");
						this.$message.success("加入成功");
					}else{
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>


<style scoped lang="scss">
	.question{
		padding: 0px 22px;
		i{
			font-size: 20px;
			font-weight: bold;
			color: #CFCFCF;
			line-height: 420px;
			cursor: pointer;
		}
		.question-left{
			width: 362px;
			height: 420px;
		}
		.question-right{
			width: 332px;
			.question-right-top{
				background: #FFFBF7;
				border-radius: 8px;
				padding: 12px 15px;
				.question-right-top-title{
					font-weight: bold;
					font-size: 16px;
					color: #16191E;
					line-height: 22px;
				}
				.question-right-top-list{
					margin-top: 5px;
					.question-right-top-list-item{
						font-weight: 400;
						font-size: 14px;
						color: #777C85;
						line-height: 20px;
						margin-top: 4px;
						width: 50%;
						text-align: left;
					}
				}
			}
			.question-right-bottom{
				margin-top: 10px;
				background: #F5F8FF;
				border-radius: 8px;
				padding: 12px 15px;
				.question-right-bottom-title{
					font-weight: 500;
					font-size: 16px;
					color: #16191E;
					line-height: 22px;
					margin-bottom: 9px;
				}
				.question-right-bottom-image{
					width: 302px;
					height: 218px;
				}
			}
		}
	}
	.dialog-footer {
		margin-top: 30px;
		.dialog-footer-two {
			width: 122px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	
		.dialog-footer-one {
			width: 114px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #fff;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
</style>