import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=d4d98324&scoped=true"
import script from "./privacy.vue?vue&type=script&lang=js"
export * from "./privacy.vue?vue&type=script&lang=js"
import style0 from "./privacy.vue?vue&type=style&index=0&id=d4d98324&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4d98324",
  null
  
)

export default component.exports