<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="840px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
		<template v-if="step == 1">
			<div class="question flex-between">
				  <i class="el-icon-arrow-left" @click="leftNum"></i>
				 <el-image class="question-left" :src="info.imageUrl" fit="contain"></el-image>
				 <div class="question-right">
					 <div class="question-right-top">
						 <div class="question-right-top-title">题目类型</div>
						 <div class="question-right-top-list flex-start flex-center flex-wrap">
							 <div class="question-right-top-list-item">考试局：{{info.examOfficeName}}</div>
							 <div class="question-right-top-list-item">类型：{{info.questionTypeName}}</div>
							 <div class="question-right-top-list-item">科目：{{info.subjectName}}</div>
							 <div class="question-right-top-list-item">考年：{{info.yearName}}年</div>
							 <div class="question-right-top-list-item">考季：{{info.examSeasonName}}</div>
							 <div class="question-right-top-list-item">难度：{{info.difficultyName}}</div>
							 <div class="question-right-top-list-item" style="width: 100%;">知识点：{{info.knowledgeName1}}-{{info.knowledgeName2}}-{{info.knowledgeName3}}</div>
						 </div>
					 </div>
					 <div class="question-right-bottom">
						 <div class="question-right-bottom-title">题目答案</div>
						<el-image class="question-right-bottom-image" :src="info.answerUrl" fit="contain"></el-image>
					 </div>
				 </div>
				  <i class="el-icon-arrow-right" @click="rightNum"></i>
			 </div>
			<span slot="footer" class="dialog-footer flex-midden flex-center">
				<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">返回</el-button>
				<el-button type="primary" class="dialog-footer-two" @click="step=2">重新上传答案</el-button>
			</span>
		</template>
		<template v-if="step == 2">
			<div class="question">
				<div class="question-title">请您上传答案</div>
				<div class="question-tip">拖动蓝框进行切图</div>
				<el-upload v-if="!image" :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess" style="text-align: center;">
					<div class="upload-image">
						<img src="@/assets/images/create/photo.png"/>
						<div>点击上传图片</div>
					</div>
				</el-upload>
				<div class="cropper" v-else>
					<vue-cropper ref="cropper" :img="image" :info="true"
						:autoCrop="options.autoCrop" :autoCropWidth="options.autoCropWidth"
						:autoCropHeight="options.autoCropHeight" :fixedBox="options.fixedBox" :full="true"
						outputType="png" :outputSize="1"></vue-cropper>
				</div>
			</div>
			<span slot="footer" class="dialog-footer flex-midden flex-center">
				<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">取消</el-button>
				<el-button type="primary" class="dialog-footer-two" :loading="loading" @click="submit">确定</el-button>
				<el-button type="primary" class="dialog-footer-two" v-if="image" @click="image=''">重新选择图片</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	
	import {getQuestionInfoByPaper,uploadimage,updatePaperQuestion} from "@/api/index"
	import {uploadUrl} from "@/api/config"
	import {
		VueCropper
	} from 'vue-cropper'
	export default {
		components: {
			VueCropper
		},
		data(){
			return {
				dialogVisible:false,
				info:{},
				index:-1,
				list:[],
				paper_id:0,
				loading:false,
				options: {
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 492, // 默认生成截图框宽度
					autoCropHeight: 207, // 默认生成截图框高度
					fixedBox: false, // 固定截图框大小 不允许改变
				},
				sort:0,
				image: "",
				step: 1,
				upload:""
			}
		},
		methods:{
			init(index, list,paper_id,sort){
				this.upload = uploadUrl();
				this.index = index;
				this.list = list;
				this.paper_id = paper_id;
				this.sort = sort;
				this.loading =false;
				this.step = 1;
				this.image = "";
				this.getInfo();
			},
			getInfo(){
				getQuestionInfoByPaper({questionId:this.list[this.index].questionId,paperId:this.paper_id}).then(res=>{
					if(res.code == 0){
						this.info = res.data;
						if(!this.dialogVisible){
							this.dialogVisible = true;
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//左侧切图
			leftNum(){
				if(this.index <= 0){
					return this.$message.error("已经是第一道题了");
				}
				this.index--;
				this.getInfo();
				
			},
			//右侧切图
			rightNum(){
				if(this.index+1 >= this.list.length){
					return this.$message.error("已经是最后一道题了");
				}
				this.index++;
				this.getInfo();
				
			},
			submit() {
				this.loading = true;
				try {
					this.$refs.cropper.getCropData(data => {
						uploadimage(data).then(res => {
							if (res.code == 0) {
								this.submitData(res.fileName);
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						})
					})
				} catch (e) {
					this.loading = false;
					this.$message.error("切图失败");
				}

				// this.dialogVisible = false;
			},
			//提交图片修改
			submitData(file) {
				updatePaperQuestion({
					id: this.info.id,
					newPaperId: this.paper_id,
					questionId: this.info.questionId,
					imageUrl: "",
					answerUrl: file
				}).then(res => {
					if (res.code == 0) {
						this.dialogVisible = false;
						this.$emit("update");
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				})
			},
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.image = e.response.filePath;
				}else{
					this.$message.error("图片上传失败");
				}
			},
		}
	}
</script>


<style scoped lang="scss">
	.question{
		padding: 0px 22px;
		i{
			font-size: 20px;
			font-weight: bold;
			color: #CFCFCF;
			line-height: 420px;
			cursor: pointer;
		}
		.question-left{
			width: 362px;
			height: 420px;
		}
		.question-right{
			width: 332px;
			.question-right-top{
				background: #FFFBF7;
				border-radius: 8px;
				padding: 12px 15px;
				.question-right-top-title{
					font-weight: bold;
					font-size: 16px;
					color: #16191E;
					line-height: 22px;
				}
				.question-right-top-list{
					margin-top: 5px;
					.question-right-top-list-item{
						font-weight: 400;
						font-size: 14px;
						color: #777C85;
						line-height: 20px;
						margin-top: 4px;
						width: 50%;
						text-align: left;
					}
				}
			}
			.question-right-bottom{
				margin-top: 10px;
				background: #F5F8FF;
				border-radius: 8px;
				padding: 12px 15px;
				.question-right-bottom-title{
					font-weight: 500;
					font-size: 16px;
					color: #16191E;
					line-height: 22px;
					margin-bottom: 9px;
				}
				.question-right-bottom-image{
					width: 302px;
					height: 218px;
				}
			}
		}
	}
	.dialog-footer {
		margin-top: 30px;
		.dialog-footer-two {
			width: 122px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	
		.dialog-footer-one {
			width: 114px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #fff;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	.question {
		position: relative;
		.question-title {
			font-weight: 500;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			padding: 0px 0px 20px;
			text-align: center;
		}
		.question-tip{
			width: 119px;
			height: 27px;
			background: #FFF6F6;
			border-radius: 10px 100px 100px 0px;
			font-weight: 400;
			font-size: 12px;
			color: #FF5858;
			line-height: 27px;
			text-align: center;
			position: absolute;
			right: 60px;
			top: 10px;
		}
	
		.cropper {
			width: 492px;
			height: 407px;
			margin: 0 auto;
		}
		
		.upload-image{
			width: 492px;
			border-radius: 8px;
			border: 2px solid #FF3838;
			margin: 0 auto;
			padding: 68px 0px;
			img{
				width: 47px;
				height: 45px;
			}
			div{
				font-weight: 400;
				font-size: 14px;
				color: #CFCFD4;
				line-height: 20px;
			}
		}
	}
</style>