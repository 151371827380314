import request from '@/utils/request'

// 学科下拉列表
export function subjectsList() {
  return request({
    url: 'backend/teacher/subjects',
    method: 'post'
  })
}

// 考试局下拉列表
export function examoffices() {
  return request({
    url: 'backend/teacher/examoffices',
    method: 'post'
  })
}

//获取学年的列表/
export function years() {
  return request({
    url: 'backend/teacher/years',
    method: 'post'
  })
}

//获取考试季度
export function examseasons() {
  return request({
    url: 'backend/teacher/examseasons',
    method: 'post'
  })
}

//获取知识等级
export function knowledgepints(data) {
  return request({
    url: 'backend/teacher/knowledgepints',
    method: 'post',
	data:data
  })
}

//获取难度列表
export function difficulties() {
  return request({
    url: 'backend/teacher/difficulties',
    method: 'post'
  })
}

//获取难度列表
export function paperTypes() {
  return request({
    url: 'backend/teacher/paperTypes',
    method: 'post'
  })
}


//获取题目类别
export function questionType() {
  return request({
    url: 'paper/question/questionTypeList',
    method: 'get'
  })
}

//获取上传地址
export function uploadUrl() {
  return "https://pcgld.internationaltestpaper.com/common/sysFile/upload2"
}

//获取图片前缀
export function imageurl() {
  return "https://pcgld.internationaltestpaper.com/"
}


//获取所在地区
export function areaList() {
  return request({
    url: 'paper/area/list2',
    method: 'get'
  })
}
