<template>
	<div class="content">
		<div class="content-table-nav flex-start flex-center">
			<div @click="toindex">自主试卷列表</div>
			<div class="active">自组判卷列表</div>
			<div @click="tocreate">新建自组试卷</div>
		</div>
		<div class="flex-between search-div">
			<el-form label-position="right" class="content-search" label-width="120px" :model="search">
				<el-row>
					  <el-col :span="6">
						  <el-form-item label="自组试卷名称">
						    <el-input v-model="search.name" size="small"></el-input>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="自组试卷状态">
						    <el-select v-model="search.answer_status" size="small" collapse-tags clear filterable placeholder="请选择" style="width: 100%;">
						        <el-option label="已判卷" :value="1"></el-option>
						        <el-option label="未判卷" :value="5"></el-option>
						      </el-select>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="答卷人">
						    <el-input v-model="search.user_name" size="small"></el-input>
						  </el-form-item>
					  </el-col>
					  <el-col :span="6">
						  <el-form-item label="答卷人手机号">
						    <el-input v-model="search.user_phone" size="small"></el-input>
						  </el-form-item>
					  </el-col>
					  <el-col :span="8">
						  <el-form-item label="创建时间">
						    <el-date-picker v-model="search.date" size="small" style="width: 100%;" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						    </el-date-picker>
						  </el-form-item>
					  </el-col>
				</el-row>
			</el-form>
			<div class="search-button flex-start flex-center">
				<div @click="reset">重置</div>
				<div @click="getTopList">搜索</div>
			</div>
		</div>
		<div class="content-table">
			 <el-table :data="tableData" style="width: 100%">
				<el-table-column type="selection" label="全选"></el-table-column>
				<el-table-column prop="name" label="自组试卷名称"></el-table-column>
				<el-table-column prop="answerStatus" label="状态" width="80">
					<template slot-scope="scope">
						<div v-if="scope.row.answerStatus == 1">已判卷</div>
						<div v-if="scope.row.answerStatus == 5" style="color: #40C88C;">未判卷</div>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="判卷类型" width="80">
					<template slot-scope="scope">
						<div v-if="scope.row.examination == 1">自己判卷</div>
						<div v-if="scope.row.examination == 2">平台判卷</div>
					</template>
				</el-table-column>
				<el-table-column prop="paperScore" label="自组试卷总分" width="110"></el-table-column>
				<el-table-column prop="answerUser" label="答卷人" width="100"></el-table-column>
				<el-table-column prop="answerPhone" label="答卷人手机号" width="120"></el-table-column>
				<el-table-column prop="answerScore" label="自组试卷得分" width="110"></el-table-column>
				<el-table-column prop="answerTime" label="交卷时间" width="160"></el-table-column>
				<el-table-column prop="createUser" label="创建人" width="130"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
				<el-table-column prop="date" label="操作" width="180">
					<template slot-scope="scope">
						<div class="flex-midden flex-center">
							<div class="see-button flex-center flex-midden" @click="tosee(scope.row)">
								<img src="@/assets/images/index/kan.png"/>
								<div>查阅</div>
							</div>
							<!-- <div v-if="scope.row.answerStatus == 1" class="see-button flex-center flex-midden" @click="changeStatus(scope.row.id, 4,'确认要下架吗？')">
								<img src="@/assets/images/index/gai.png"/>
								<div>修改</div>
							</div> -->
							<div v-if="scope.row.answerStatus == 5" class="see-button flex-center flex-midden" @click="toscore(scope.row)">
								<img src="@/assets/images/index/pan.png"/>
								<div>判卷</div>
							</div>
						</div>
					</template>
				</el-table-column>
			  </el-table>
			  <div class="table-bottom flex-between flex-center">
					<div class="table-bottom-left flex-start flex-center">
						<div>已选择 <span>0</span> 条</div>
						<div class="flex-midden flex-center">
							<img src="@/assets/images/down.png"/>
							<span>下载自组试卷</span>
						</div>
					</div>
					<div class="table-bottom-right">
						<el-pagination :current-page="page" @size-change="changeSize" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="sizes, prev, pager, next, jumper" :total="total" @current-change="getNextPage">
						</el-pagination>
					</div>
			  </div>
		</div>
		
	</div>
</template>

<script>
	import {subjectsList,examoffices,paperTypes,questionType} from "@/api/config"
	import {paperAnswerList} from "@/api/index"
	export default {
		data(){
			return {
				tableData:[],
				page:1,
				total:0,
				pageSize:10,
				search:{
					name:"",
					answer_status:"",
					user_name:"",
					user_phone:"",
					date:[]
				},
				subject:[],
				examname:[],
				paperTypeList:[],
				questionTypeList:[]
			}
		},
		created(){
			this.getConfig();
			this.getList();
		},
		methods:{
			getConfig(){
				subjectsList().then(res=>{
					this.subject = res.data;
				})
				examoffices().then(res=>{
					this.examname = res.data;
				})
				//获取试卷类型
				paperTypes().then(res=>{this.paperTypeList = res.data;})
				//获取题目类别
				questionType().then(res=>{this.questionTypeList = res.data;})
			},
			tocreate(){
				this.$router.push("/create");
			},
			toindex(){
				this.$router.push("/");
			},
			getList(){
				paperAnswerList({
					name:this.search.name,
					answer_status:this.search.answer_status==''?0:this.search.answer_status,
					user_name:this.search.user_name,
					user_phone:this.search.user_phone,
					answer_time_start:this.search.date.length == 2?this.search.date[0]:'',
					answer_time_end:this.search.date.length == 2?this.search.date[1]:'',
					user_id:Number(this.$cookies.get('teacheruserId')),
					limit:this.pageSize,
					offset:this.page,
					source:"client",
				}).then(res=>{
					if(res.code == 0){
						this.tableData = res.rows;
						this.total = res.total;
					}
				})
			},
			getTopList(){
				this.page = 1;
				this.getList();
			},
			getNextPage(e){
				this.page = e;
				this.getList();
			},
			reset(){
				this.search = {
					name:"",
					answer_status:"",
					user_name:"",
					user_phone:"",
					date:[]
				}
				this.getTopList();
			},
			tosee(info){
				this.$router.push("/examinfo?id="+info.paperId+"&answerId="+info.answerId);
			},
			toscore(info){
				this.$router.push("/submitscore?id="+info.paperId+"&answerId="+info.answerId);
			},
			//改变条数
			changeSize(e){
				this.pageSize = e;
				this.getTopList();
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/table.scss";
</style>