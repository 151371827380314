<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="270px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="msg">
		 <div class="msg-title">温馨提示</div>
		 <div class="wechatcode-desc flex-center flex-midden">
			 <div>错误类型</div>
			 <el-select v-model="error_type_id" placeholder="请选择" style="width: 150px;">
				<el-option v-for="item in list" :label="item.name" :value="item.id"></el-option>
			 </el-select>
		 </div>
	 </div>
	  <span slot="footer" class="dialog-footer flex-midden flex-center">
		  <div class="dialog-footer-one" @click="dialogVisible=false">取消</div>
		  <div class="dialog-footer-two" @click="submit">提交</div>
	  </span>
	</el-dialog>
</template>

<script>
	import {questionErrorTypes,submitQuestionError} from "@/api/index"
	export default {
		data(){
			return {
				dialogVisible:false,
				id:"",
				list:[],
				error_type_id:""
			}
		},
		methods:{
			init(id){
				this.id = id;
				questionErrorTypes().then(res=>{
					if(res.code == 0){
						this.list = res.data;
						this.dialogVisible = true;
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			submit(){
				submitQuestionError({
					question_id:this.id,
					error_type_id:this.error_type_id
				}).then(res=>{
					if(res.code == 0){
						this.dialogVisible = false;
						this.$message.success("提交成功");
					}else{
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>


<style scoped lang="scss">
	.msg{
		.msg-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 0px 0px 11px;
		}
		.wechatcode-desc{
			font-weight: 400;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: center;
			margin-bottom: 15px;
			div{
				margin-right: 5px;
			}
		}
	}
	.dialog-footer{
		.dialog-footer-one{
			width: 75px;
			height: 34px;
			border-radius: 4px;
			border: 1px solid #FF8D1F;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
		.dialog-footer-two{
			width: 75px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			line-height: 34px;
			text-align: center;
			margin: 0px 10px;
			cursor: pointer;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
</style>