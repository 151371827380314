<template>
	<div class="usercneter">
		<div class="center-content flex-start">
			<centerLeft :menu="1"></centerLeft>
			<div class="user-info">
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">头像</div>
					<el-upload :action="upload" name="file" :limit="1" :show-file-list="false" :before-upload="checkImage" :on-success="imageSuccess">
						<div class="user-info-item-avatar">
							<img v-if="avatar" :src="avatar"/>
							<img v-else src="@/assets/images/login/avatar.png"/>
							<div>点击可更换头像</div>
						</div>
					</el-upload>
					
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">姓名</div>
					<div class="user-info-item-input">
						<el-input placeholder="请输入姓名" v-model="form.nickname"></el-input>
					</div>
				</div>
				
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">所在地区</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" v-model="form.region" placeholder="请选择所在地区">
						    <el-option v-for="item in area" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">学校</div>
					<div class="user-info-item-input">
						<el-input placeholder="请输入学校" v-model="form.school"></el-input>
					</div>
				</div>
				<div class="user-info-item flex-start flex-center">
					<div class="user-info-item-title">课程体系</div>
					<div class="user-info-item-input">
						<el-select style="width: 100%;" v-model="form.courseSystem" placeholder="请选择课程体系">
						    <el-option v-for="item in examofficesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							
						</el-select>
					</div>
				</div>
				
				<div class="user-info-button" @click="submit">保存</div>
			</div>
		</div>
	</div>
</template>

<script>
	import centerLeft from "@/components/center-left.vue"
	import {getUserByToken,dataFilling} from "@/api/index"
	import {uploadUrl,areaList,examoffices} from "@/api/config"
	export default {
		components:{
			centerLeft
		},
		data(){
			return {
				form:{
					nickname:"",
					region:"",
					school:"",
					courseSystem:"",
					avatar:""
				},
				upload:"",
				avatar:"",
				area:[],
				examofficesList:[]
			}
		},
		mounted() {
			this.upload = uploadUrl();
			areaList().then(res=>{
				this.area = res.data;
			})
			examoffices().then(res=>{
				this.examofficesList = res.data;
			})
			getUserByToken().then(res=>{
				this.form.nickname = res.data.nickname;
				this.form.region = Number(res.data.region);
				this.form.school = res.data.school;
				this.form.courseSystem = Number(res.data.courseSystem);
				this.form.avatar = "";
				this.avatar = res.data.avatar;
			})
		},
		methods:{
			
			//限制图片的相关格式和大小
			checkImage(file){
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg');
				const isLt2M = file.size / 1024 / 1024 < 6;
				if (!isJPG) {
				  this.$message.error('上传图片只能是JPG,JPEG,PNG格式!');
				}
				return isJPG && isLt2M;
			},
			//上传观测点的图片成功
			imageSuccess(info,e){
				if(e.response.code == 0){
					this.avatar = e.response.filePath;
					this.form.avatar = e.response.fileName;
				}else{
					this.$message.error("图片上传失败");
				}
			},
			submit(){
				if(!this.form.avatar && !this.avatar){
					return this.$message.error("请上传头像");
				}
				if(!this.form.nickname){
					return this.$message.error("请输入姓名");
				}
				if(!this.form.region){
					return this.$message.error("请选择地区");
				}
				if(!this.form.school){
					return this.$message.error("请输入学校");
				}
				if(!this.form.courseSystem){
					return this.$message.error("请选择课程体系");
				}
				dataFilling(this.form).then(res=>{
					if(res.code == 0){
						location.reload();
						this.$message.success("保存成功");
					}else{
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "./index.scss";
</style>